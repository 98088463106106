var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MyLeftMeun", {
    staticClass: "custom-left-menu",
    attrs: {
      platIconTitleData: _vm.platIconTitleData,
      customTitle: _vm.customTitle,
      menus: _vm.menusData,
      asideLogoUrl: "/river-fs/file/view?state=public&clientId=111&fileId=",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }