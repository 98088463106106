<template>
  <MyLeftMeun
    :platIconTitleData="platIconTitleData"
    :customTitle="customTitle"
    :menus="menusData"
    asideLogoUrl="/river-fs/file/view?state=public&clientId=111&fileId="
    class="custom-left-menu"
  ></MyLeftMeun>
</template>
<script>
import { mapGetters } from 'vuex';
import UserUtil from '@/utils/UserUtil';
import { THEME_DATA } from '@/mock/ThemeMock';
import MyLeftMeun from '@/components/my-left-meun/main.vue';
export default {
  name: 'RiverLeftMenu',
  components: {
    MyLeftMeun
  },
  data() {
    return {
      platIconTitleData: {},
      menusData: null,
      // 菜单临时 icon 按照顺序展示 来自于 https://www.iconfont.cn/ 需要先加入项目

      icon: {
        任务处理: 'icon-renwuchuli',
        新建任务: 'icon-xinjianrenwu',
        待办事项: 'icon-daibanshixiang',
        已办事项: 'icon-yiban',
        预知事项: 'icon-ruanzhuyuce',
        发起事项: 'icon-shenqingxialaiderenwu-copy',
        印控日志管理: 'icon-shenqingxialaiderenwu-copy',

        应用管理: 'icon-yingyongguanli',
        流程管理: 'icon-liucheng',
        应用数据: 'icon-shuju01',
        事项管理: 'icon-daibanshixiang',
        个人归档: 'icon-guidang',
        委托管理: 'icon-wodeweituo',

        企业组织: 'icon-zuzhi',
        公司管理: 'icon-gongsiguanli',
        部门管理: 'icon-suyaniconchanpinleibufenzuodaohangbufen87',
        职务管理: 'icon-zhiwei',
        人员管理: 'icon-renyuanguanli1',

        人事管理: 'icon-renshiguanli-zhaopinguanli',
        人事状态变更: 'icon-renyuanguanli',
        人事调动管理: 'icon-yuangongtiaodongshenqingbiao',

        系统管理: 'icon-xitongguanli',
        菜单管理: 'icon-caidanguanli',
        角色管理: 'icon-jiaoseguanli',
        分权管理: 'icon-fenquan',
        语言资源: 'icon-yuyan',
        单点配置: 'icon-1fuwu-dandiandenglu-01'
      }
    };
  },
  mounted() {
    // 获取初始化数据
    this.initData();
    this.getPlatIconTitle();
  },
  props: {
    customTitle: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['menus'])
  },
  methods: {
    /**
     * 获取菜单数据
     * @param {number} param
     * @returns {number}
     */
    initData() {
      let menusData = this.buildMenus(this.menus);

      menusData.map((item, index) => {
        if (this.icon[item.menuName]) {
          item.menuNoActiveIcon = this.icon[item.menuName];
        }
        if (item.children && item.children.length) {
          item.children.map((sItem, sIndex) => {
            sItem.menuNoActiveIcon = this.icon[sItem.menuName];
          });
        }
      });
      this.menusData = menusData;
      // console.log(this.menusData);
    },

    buildMenus(menus) {
      let result = [];
      menus.forEach(item => {
        let tmp = {
          appCode: item.code,
          hiddenFlag: 'N',
          menuActiveIcon: null,
          menuCode: item.id,
          menuId: item.id,
          menuName: item.displayName,
          menuNoActiveIcon: 'iconfanwei1',
          menuPid: item.parentId,
          menuRouterFilePath: null,
          menuRouterUrl: item.route,
          menuSort: item.sort,
          menuTips: null,
          namePinYin: null,
          children: [],
          icon: item.icon
        };
        if (item.children && item.children.length) {
          tmp.children = this.buildMenus(item.children);
        }
        result.push(tmp);
      });
      return result;
    },

    /**
     * 获取登录页面，布局页面配置
     * @param {number} param
     * @returns {number}
     */
    getPlatIconTitle() {
      let logoId = UserUtil.getLoginTenants()?.logo || '';
      this.platIconTitleData = { ...THEME_DATA, icontitleCompanyLogo: logoId, icontitleDoorLogo: logoId };
      this.platIconTitleData.icontitlePlatCnname = this.$t(this.platIconTitleData.icontitlePlatCnname);
      this.platIconTitleData.icontitleCompany = this.$t(this.platIconTitleData.icontitleCompany);
      this.platIconTitleData.icontitleDoorTitle = this.$t(this.platIconTitleData.icontitleDoorTitle);
    }
  }
};
</script>

<style scoped lang="scss">
.custom-left-menu {
  /deep/.aside-logo {
    margin-bottom: 2px;
    img {
      height: 48px;
      max-width: 50px;
    }
    .aside-title {
      font-size: var(--rootFontSize2);
    }
  }
  /deep/.el-menu-item {
    font-size: var(--rootFontSize);
  }
  /deep/.el-submenu__title {
    font-size: var(--rootFontSize);
  }

  /deep/ .el-menu--collapse .el-menu-item {
    color: inherit !important;
    i {
      color: inherit !important;
    }
  }
}
</style>
