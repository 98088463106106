var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap-tags" }, [
    _c("div", { staticClass: "tags-box" }, [
      _c(
        "div",
        { staticClass: "tags-list" },
        _vm._l(_vm.selectedTags, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "tag-item",
              class: { "is-active": _vm.nowTagValue == item.value },
              on: {
                click: function ($event) {
                  return _vm.openUrl(item)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "tag-text", class: { "no-close": !item.close } },
                [_vm._v(_vm._s(item.label))]
              ),
              item.close
                ? _c("i", {
                    staticClass: "el-icon-close tag-close",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.closeTag(item)
                      },
                    },
                  })
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }