<template>
  <el-container class="wrap-container">
    <river-left-menu></river-left-menu>
    <el-container direction="vertical">
      <river-header></river-header>
      <river-breadcrumb></river-breadcrumb>
      <!--      <glb-breadcrumb></glb-breadcrumb>-->
      <el-main class="wrap-main">
        <!-- <router-view /> -->
        <transition name="fade-transform" mode="out-in">
          <router-view />
        </transition>
        <!-- <keep-alive>
          <transition name="fade-transform" mode="out-in">
            <router-view v-if="$route.meta.keepAlive" />
          </transition>
        </keep-alive>
        <transition name="fade-transform" mode="out-in">
          <router-view v-if="!$route.meta.keepAlive" />
        </transition> -->
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import RiverHeader from './components/river-header';
import RiverBreadcrumb from './components/river-breadcrumb';
import RiverLeftMenu from './components/river-left-menu';

export default {
  name: 'index',
  data() {
    return {};
  },
  components: {
    RiverHeader,
    RiverBreadcrumb,
    RiverLeftMenu
  },
  methods: {
    /**
     * 获取菜单数据
     * @param {number} param
     * @returns {number}
     */
    initData() {}
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  overflow-x: hidden;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  overflow-x: hidden;
  transform: translateX(-30px);
}

.wrap-container {
  height: 100%;

  .wrap-main {
    padding: 10px;
  }
}
.main-scrollbar {
  height: 100%;

  /deep/ .is-horizontal {
    display: none;
  }

  /deep/ .el-scrollbar__wrap {
    overflow: auto;
    overflow-x: hidden;
  }

  /deep/ .el-scrollbar__view {
    height: 100%;
  }
}
</style>
