var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-aside",
    {
      staticClass: "aside-menu",
      attrs: { width: _vm.isCollapse ? "64px" : "250px" },
    },
    [
      _c(
        "div",
        {
          staticClass: "collapse-icon",
          style: { left: _vm.isCollapse ? "74px" : "260px" },
          on: {
            click: function ($event) {
              _vm.isCollapse = !_vm.isCollapse
            },
          },
        },
        [
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isCollapse,
                expression: "isCollapse",
              },
            ],
            staticClass: "el-icon-s-unfold",
            style: {
              color: "" + _vm.platIconTitleData.menuCollapseButtonColor,
            },
          }),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isCollapse,
                expression: "!isCollapse",
              },
            ],
            staticClass: "el-icon-s-fold",
            style: {
              color: "" + _vm.platIconTitleData.menuCollapseButtonColor,
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "aside-logo",
          style: { padding: _vm.isCollapse ? "" : "0 10px" },
        },
        [
          _c("img", {
            attrs: {
              src: _vm.asideLogoUrl + _vm.platIconTitleData.icontitleDoorLogo,
            },
          }),
          !_vm.isCollapse
            ? _c(
                "div",
                {
                  staticClass: "aside-title",
                  style: {
                    color: "" + _vm.platIconTitleData.icontitleDoorTitleColor,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.customTitle
                          ? _vm.customTitle
                          : _vm.platIconTitleData.icontitleDoorTitle
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.menuLoading,
                  expression: "menuLoading",
                },
              ],
              staticClass: "menu-wrap",
              attrs: {
                "default-active": _vm.activeId,
                mode: "vertical",
                "show-timeout": 200,
                "text-color": "#fff",
                "unique-opened": "",
                collapse: _vm.isCollapse,
                "active-text-color": "#fff",
              },
            },
            [
              _c("menu-item", {
                attrs: {
                  token: _vm.token,
                  iconViewUrl: _vm.iconViewUrl,
                  menus: _vm.menus,
                  collapse: _vm.isCollapse,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }