var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "wrap-container" },
    [
      _c("river-left-menu"),
      _c(
        "el-container",
        { attrs: { direction: "vertical" } },
        [
          _c("river-header"),
          _c("river-breadcrumb"),
          _c(
            "el-main",
            { staticClass: "wrap-main" },
            [
              _c(
                "transition",
                { attrs: { name: "fade-transform", mode: "out-in" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }